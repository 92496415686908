export const ENV = {
  AP_GITHUB_TOKEN: process.env.NEXT_PUBLIC_AP_GITHUB_TOKEN,
  API_KEY: process.env.NEXT_PUBLIC_AG_PRO_API_KEY,
  APRO_URL: process.env.NEXT_PUBLIC_APRO_BASE_URL,
  BACK_URL: process.env.NEXT_PUBLIC_BACK_BASE_URL,
  COOKIE_DOMAIN: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
  DLOCAL_API_KEY: process.env.NEXT_PUBLIC_DLOCAL_SMART_FIELD_API_KEY_MX,
  DLOCAL_FIELD_URL: process.env.NEXT_PUBLIC_DLOCAL_SMART_FIELD_URL,
  ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
  FB_PIXEL_ID: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
  FRONTEND_COOKIE: process.env.NEXT_PUBLIC_FRONTEND_COOKIE,
  GA_TRACKING_ID: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  URL: process.env.NEXT_PUBLIC_API_URL,
  FIREBASE: {
    API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    AUTH_DOMAIN: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    STORAGE_BUCKET: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    MESSAGING_SENDER_ID: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  },
  SENTRY: {
    AUTH_TOKEN: process.env.NEXT_PUBLIC_SENTRY_AUTH_TOKEN,
    KEY: process.env.NEXT_PUBLIC_SENTRY_KEY,
    INTERNAL_ORG: process.env.NEXT_PUBLIC_SENTRY_INTERNAL_ORG,
    INTERNAL_PROJECT: process.env.NEXT_PUBLIC_SENTRY_INTERNAL_PROJECT,
  },
  HOTJAR: {
    ID: process.env.NEXT_PUBLIC_HOTJAR_ID,
    VERSION: process.env.NEXT_PUBLIC_HOTJAR_VERSION,
  },
};
